<section class="navbar" dir="rtl">
  <nav class="navbar navbar-expand-lg navbar-light bg-white container my-4">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/Group 36501.svg" alt="" class="img-fluid" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a
            class="nav-link btn shadow-none px-3"
            [routerLink]="['/ar']"
            routerLinkActive="router-link-active"
            style="background: #a2e1ff"
            >الصفحة الرئيسية
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('about')"
            >معلومات عنا</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('products')"
            >منتجات</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none " (click)="scrollTo('contact')"
            >اتصل بنا</a
          >
        </li>
      </ul>
    </div>
    <ul class="navbar-nav  pr-0">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['']"
          routerLinkActive="router-link-active"
          >English</a
        >
      </li>
    </ul>
  </nav>
</section>

<section class="header text-right" dir="rtl">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3">
        <h1>
          الشركة <br />
          <span style="color: #0180b9"> المصرية الصناعية </span>
        </h1>
        <p>مُصنِّع ومورد لصب قوالب الألمنيوم عالي الضغط مكونات آلية.</p>
        <button class="btn mt-4" (click)="scrollTo('contact')">
          نبدأ الآن
        </button>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/Group 36517.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>

<section class="about text-right" dir="rtl">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/Group 36518.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-6 mt-3">
        <h1>معلومات عنا</h1>
        <p>
          تأسست الشركة المصرية للصناعات في عام 2007 و تعمل الشركة في مجال حقن الألمنيوم ذات الضغط العالي والمكونات الآلية. تدار الشركة من قبل فريق من المهندسين المختصين فى هذا المجال و المدربين تدريباً احترافياً ويركز الفريق بأكمله على إرضاء العملاء و يبذل  قصارى جهده لضمان جودة المكونات في كل مرحلة من مراحل الانتاج والتأكد من اعلي معاير الجودة.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="products text-right" dir="rtl">
  <div class="container">
    <h1 class="text-center">المنتجات</h1>
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <h2>تنورة من الألومنيوم</h2>
        <h3>شعلة بأغطية</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img src="../../assets/images/IMG_3384.png" alt="" class="img-fluid" />
      </div>
    </div>

    <div class="row d-flex align-items-center" dir="ltr">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <h2>شعلة الألمنيوم</h2>
        <h3>مع إغلاق النحاس</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/20201023_171844.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <h2>مات ايناميل</h2>
        <h3>قبعات الموقد</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/images.jpeg-17.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div class="row d-flex align-items-center" dir="ltr">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <!-- <h2>تنورة من الألومنيوم</h2> -->
        <h3>أغطية الموقد النحاسي</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/20200925_114305.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>

<section class="contact text-right" dir="rtl">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 pt-3">
        <h1>اتصل بنا</h1>
        <p>
          تأسست الشركة المصرية الصناعية عام 2007 وقد قامت الشركة تطورت نفسها
          كشركة مصنعة وموردة للضغط العالي صب الألومنيوم ومكونات تشكيله.
        </p>
        <div >
          <p>
            <i class="fas fa-map-marker-alt pl-2"></i> Plot 176 Industrial Area,
            Third New Cairo ,Cairo Governerate <br> <br>
            <i class="fas fa-map-marker-alt pl-2"></i> عنوان المكتب: 15 El Gad Street Bab Elsharia ,Cairo Governerate
          </p>
          <p>
            <i class="fas fa-phone pl-2"></i>
            <span dir="ltr">
              <a class="pr-4" href="tel:+20225732555">+20225732555</a>
              <a class="pr-2" href="tel:+201222391378">+201222391378</a>
            </span>
          </p>
          <p>
            <i class="fas fa-fax pl-2"></i>
            <a href="fax:+2025918980"
              >2025918980+</a
            >
          </p>
          <p>
            <i class="far fa-envelope pl-2"></i>
            <a href="mailto:info@egindustrial.com"
              >info@egindustrial.com</a
            >
          </p>
          <p>
            <i class="fab fa-instagram pl-2"></i>
            <a href="https://instagram.com/egyptianindustrialcompany?utm_medium=copy_link">https://instagram.com/egyptianindustrialcompany?utm_medium=copy_link</a>
          </p>
        </div>
      </div>
      <div class="col-md-6 pt-3">
        <div class="form">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="mt-4">
              <input
                class="form-control form-control-lg shadow-none"
                type="text"
                placeholder="اسمك"
                formControlName="name"
              />
              <app-fielderror
                [displayError]="isFieldValid('name')"
                errorMsg="رجاء ادخل اسما صحيحا"
              >
              </app-fielderror>
            </div>
            <div class="mt-4">
              <input
                class="form-control form-control-lg shadow-none"
                type="number"
                placeholder="رقم الهاتف"
                formControlName="phone"
              />
              <app-fielderror
                [displayError]="isFieldValid('phone')"
                errorMsg="يرجى إدخال رقم هاتف صالح"
              ></app-fielderror>
            </div>
            <div class="mt-4">
              <input
                class="form-control form-control-lg shadow-none"
                type="email"
                placeholder="عنوان البريد الإلكتروني"
                formControlName="email"
              />
              <app-fielderror
                [displayError]="isFieldValid('email')"
                errorMsg="يرجى إدخال عنوان بريد إلكتروني صالح"
              ></app-fielderror>
            </div>
            <!-- <div class="mt-4">
              <textarea
                name="note"
                id=""
                rows="3"
                class="w-100 form-control form-control-lg shadow-none"
                placeholder="Message"
                formControlName="note"
              ></textarea>
            </div> -->
            <div class="text-center mt-5">
              <button type="submit" class="btn-form">نبدأ الآن</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="footer mt-5"  >
      <div class="d-md-flex justify-content-between flex-wrap">
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('navbar')"
          >الصفحة الرئيسية
        </a>
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('about')"
          >معلومات عنا</a
        >
        <img
          src="../../assets/images/Group 36516.svg"
          alt=""
          class="img-fluid pt-md-0 pt-5"
        />
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('products')"
          >منتجات</a
        >
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('contact')"
          >اتصل بنا</a
        >
      </div>
    </div>
  </div>
</section>
