<section class="navbar">
  <nav class="navbar navbar-expand-lg navbar-light bg-white container my-4">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/Group 36501.svg" alt="" class="img-fluid" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a
            class="nav-link btn shadow-none px-3"
            [routerLink]="['']"
            routerLinkActive="router-link-active"
            style="background: #a2e1ff"
            >Home
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('about')"
            >About Us</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('products')"
            >Products</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link btn shadow-none" (click)="scrollTo('contact')"
            >Contact Us</a
          >
        </li>
      </ul>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/ar']"
          routerLinkActive="router-link-active"
          >Arabic</a
        >
      </li>
    </ul>
  </nav>
</section>

<section class="header">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3">
        <h1>
          <span style="color: #0180b9">Egyptain Industrial <br> </span> Company
        </h1>
        <p>
          a manufacturer and supplier of high pressure aluminium die casting and
          machined components.
        </p>
        <button class="btn mt-4" (click)="scrollTo('contact')">GET STARTED NOW</button>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/Group 36517.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>

<section class="about">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/Group 36518.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-md-6 mt-3">
        <h1>ABOUT US</h1>
        <p>
          Egyptian Industrial Company was incorporated in2007 The company has
          evolved itself as a manufacturer and supplier of high pressure
          aluminium die casting and machined components. The company is managed
          by a team of professionally trained managers and engineers and the
          entire team focuses towards customer satisfaction and upgrades itself
          to match the changing customer requirements. The team takes every care
          to ensure the quality of components at each stage of process and
          ensure perfect free product is passed on to the customers.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="products">
  <div class="container">
    <h1 class="text-center">Products</h1>
    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <h2>SKIRT ALUMINUM</h2>
        <h3>BURNER WITH CAPS</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img src="../../assets/images/IMG_3384.png" alt="" class="img-fluid" />
      </div>
    </div>

    <div class="row d-flex align-items-center" dir="rtl">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <h2>ALUMINUM BURNER</h2>
        <h3>WITH CLOSE BRASS</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/20201023_171844.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div class="row d-flex align-items-center">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <h2>MATT ENAMELLE</h2>
        <h3>BURNER CAPS</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/images.jpeg-17.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>

    <div class="row d-flex align-items-center" dir="rtl">
      <div class="col-md-6 mt-3 d-flex flex-column align-items-center">
        <!-- <h2>SKIRT ALUMINUM</h2> -->
        <h3>BRASS BURNER CAPS</h3>
      </div>
      <div class="col-md-6 mt-3">
        <img
          src="../../assets/images/20200925_114305.png"
          alt=""
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</section>

<section class="contact">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-6 pt-3">
        <h1>Contact Us</h1>
        <p>
          Egyptian Industrial Company was incorporated in2007 The company has
          evolved itself as a manufacturer and supplier of high pressure
          aluminium die casting and machined components.
        </p>
        <div>
          <p>
            <i class="fas fa-map-marker-alt pr-2"></i> Plot 176 Industrial Area,
            Third New Cairo ,Cairo Governerate <br> <br>
            <i class="fas fa-map-marker-alt pr-2"></i> Office: 15 El Gad Street Bab Elsharia ,Cairo Governerate
          </p>
          <p>
            <i class="fas fa-phone pr-2"></i>
            <a class="pr-2" href="tel:+20225732555">+20225732555</a>
            <a class="pr-2" href="tel:+201222391378">+201222391378</a>
          </p>
          <p>
            <i class="fas fa-fax pr-2"></i>
            <a href="fax:+2025918980"
              >+2025918980</a
            >
          </p>
          <p>
            <i class="far fa-envelope pr-2"></i>
            <a href="mailto:info@egindustrial.com"
              >info@egindustrial.com</a
            >
          </p>
          <p>
            <i class="fab fa-instagram pr-2"></i>
            <a href="https://instagram.com/egyptianindustrialcompany?utm_medium=copy_link">https://instagram.com/egyptianindustrialcompany?utm_medium=copy_link</a>
          </p>
        </div>
      </div>
      <div class="col-md-6 pt-3">
        <div class="form">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="mt-4">
              <input
                class="form-control form-control-lg shadow-none"
                type="text"
                placeholder="Your Name"
                formControlName="name"
              />
              <app-fielderror
                [displayError]="isFieldValid('name')"
                errorMsg="Please enter a valid name"
              >
              </app-fielderror>
            </div>
            <div class="mt-4">
              <input
                class="form-control form-control-lg shadow-none"
                type="number"
                placeholder="Phone Number"
                formControlName="phone"
              />
              <app-fielderror
                [displayError]="isFieldValid('phone')"
                errorMsg="Please enter a valid phone number"
              ></app-fielderror>
            </div>
            <div class="mt-4">
              <input
                class="form-control form-control-lg shadow-none"
                type="email"
                placeholder="Email Address"
                formControlName="email"
              />
              <app-fielderror
                [displayError]="isFieldValid('email')"
                errorMsg="Please enter a valid email address"
              ></app-fielderror>
            </div>
            <!-- <div class="mt-4">
              <textarea
                name="note"
                id=""
                rows="3"
                class="w-100 form-control form-control-lg shadow-none"
                placeholder="Message"
                formControlName="note"
              ></textarea>
            </div> -->
            <div class="text-center mt-5">
              <button type="submit" class="btn-form">GET STARTED NOW</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="footer mt-5">
      <div class="d-md-flex justify-content-between  flex-wrap">
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('navbar')" 
          >Home </a>
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('about')"
          >About Us</a>
          <img src="../../assets/images/Group 36516.svg" alt="" class="img-fluid pt-md-0 pt-5">
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('products')"
          >Products</a>
        <a class="nav-link pt-5 btn shadow-none" (click)="scrollTo('contact')"
          >Contact Us</a>
      </div>
    </div>
  </div>
</section>
